import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function CheckoutComponent(props) {
  const initialOptions = {
    "clientId": "AefdUdOQ-yc72YwU8lpnQUXparutKSbgI5Tj-L2wfdGPJ70U294TB4l86HMpIpHTjEHIck9Qf5KxaK1a",
    "currency": "EUR"
  };

  const [message, setMessage] = useState("");

  return (
    <div className="App" style={{"width": "100%"}}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={async () => {
            try {
              let formData = new FormData();
            formData.append('item_amt', 19.99);
            formData.append('tax_amt', 0);
            formData.append('handling_fee', 0);
            formData.append('insurance_fee', 0);
            formData.append('shipping_amt', 0);
            formData.append('shipping_discount', 0);
            formData.append('total_amt', 19.99*props.getNumberOfKeys());
            formData.append('currency', "EUR");
            formData.append('numberOfKeys', props.getNumberOfKeys());
              formData.append('return_url',  '/return' + '?commit=true');
              formData.append('cancel_url', '/cancel');

              const response = await fetch("/paypal/createOrder.php", {
                method: "POST",
                // use the "body" param to optionally pass additional order information
                // like product ids and quantities
                body: formData,
              });

              const orderData = await response.json();
              debugger;
              if (orderData.data.id) {
                return orderData.data.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Checkout...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/paypal/getOrderDetails.php`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
              debugger;
              const orderData = await response.json();
              console.log(orderData);
              props.onApprove(orderData);
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

             /* const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                );
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }*/
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`,
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default CheckoutComponent;
