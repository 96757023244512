import logo from './logo.svg';
import './App.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getTranslationService } from './TranslationService';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Room1 from './Room1';
import Lobby from './Lobby';
import LandingPage from './page/LandingPage';
import Room2 from './Room2';
import Room3 from './Room3';
import Room4 from './Room4';
import EnterPage from './page/EnterPage';
import LoginPage from './page/LoginPage';
import LandingPage2 from './page/LandingPage2';
import BuyPage from './page/BuyPage';
import SteamPage from './page/SteamPage';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {translation: 0};
    
    //window.addEventListener("error", (event) => {debugger;});
  }

  componentDidMount() {
    const self = this;
    getTranslationService().addCallback("translationChange", ()=>{
      self.setState({"translation": Math.random()})
    });
  }
  render() {
    /*
          <Route exact path="/enter/*" element={<EnterPage translation={this.state.translation}/>}/>
          <Route path="/login/*" element={<LoginPage translation={this.state.translation}/>}/>
          <Route path="/buy/*" element={<BuyPage translation={this.state.translation}/>}/>
          <Route path="/steam/*" element={<SteamPage translation={this.state.translation}/>}/>
          <Route path="/old/*" element={<LandingPage translation={this.state.translation}/>}/>*/
    return <Router>
        <Routes>
          <Route exact path="/room/*" element={<Room1 translation={this.state.translation} />}/>
          <Route exact path="/room2/*" element={<Room2 translation={this.state.translation}/>}/>
          <Route exact path="/room3/*" element={<Room3 translation={this.state.translation}/>}/>
          <Route exact path="/room4/*" element={<Room4 translation={this.state.translation}/>}/>
          <Route exact path="/lobby/*" element={<Lobby translation={this.state.translation}/>}/>
          <Route path="/steam/*" element={<LoginPage translation={this.state.translation}/>}/>
          <Route path="*" element={<LandingPage2 translation={this.state.translation}/>}/>
        </Routes>
    </Router>;
  }

}

export default App;
