import './BuyPage.css';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from '../framework/ThreeRenderService'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaw, faCube, faLayerGroup, faEarthAmericas, faPeopleGroup, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faChrome } from '@fortawesome/free-brands-svg-icons'

import { getTranslationService } from '../TranslationService';
import LanguageSelector from '../framework/LanguageSelector';
import PlayerRepresentation from '../framework/PlayerRepresentation';
import { getDataService } from '../framework/DataService';
import { Crowded } from '../crowded';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import CheckoutComponent from '../CheckoutComponent';

class BuyPage extends Component {
  constructor(props) {
    super(props);

    this.state = { address:["","","",""], session_keys: [], numberOfKeys: 1, total: 19.99 };
    this.orderApproved = this.orderApproved.bind(this);
    this.finalizeOrder = this.finalizeOrder.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onNumberOfKeysChange = this.onNumberOfKeysChange.bind(this);
    this.getNumberOfKeys = this.getNumberOfKeys.bind(this);
  }

  orderApproved(approveDataOrig){
    debugger;
    let approveData = approveDataOrig.data;
    if(approveData.status != "APPROVED" || approveData.intent != "CAPTURE"){
        alert("Zahlung konnte nicht abgeschlossen werden.");
        this.setState({orderFailed: true});
        return;
    }

    let shipping = approveData.purchase_units[0].shipping;
    let full_name = shipping.name.full_name;
    let address = [shipping.address.address_line_1, shipping.address.postal_code, shipping.address.admin_area_1];
    let links = approveData.links;
    let numberOfKeys = approveData.purchase_units[0].items[0].quantity;
    let total = approveData.purchase_units[0].amount.value;

    this.setState({name: full_name, address: address, approveData: approveData, orderApproved: true, links: links, numberOfKeys: numberOfKeys, total: total});
    
  }

  finalizeOrder(){
    const self = this;
    fetch("/paypal/captureOrder.php", {
      method: "POST",
      body: JSON.stringify({
        userId: 1,
        title: "Fix my bugs",
        completed: false
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then((response) => response.json())
    .then((json) => {
      console.log(json);
      self.setState({showInvoice: true, invoice_number: json.invoice_number, invoice_date: json.invoice_date, session_keys: json.session_keys});
      debugger;
    });;
  }

  closeDialog(){
    const self = this;
    self.setState({showInvoice: false, orderApproved: false});
  }

  onNumberOfKeysChange(event){
    let val = event.target.value;
    if(val >10 || val < 1){
      val = Math.max(1,Math.min(10, val));
    }
    this.setState({numberOfKeys: val});
    console.log("new number of keys", val);
  }

  getNumberOfKeys(){
    return this.state.numberOfKeys;
  }

  componentDidMount() {

  }

  render() {
    const self = this;

    return [
      <section className="hero is-info is-medium is-bold" id="home">
        <div className="hero-head">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <a className="navbar-item" href="../">

                  <img className="image" src="/images/escape_party.svg" alt="" style={{ maxHeight: "unset" }} width="120px" />
                </a>
                <span className={"navbar-burger burger " + (this.state.burger ? "is-active" : "")} data-target="navbarMenu" onClick={() => { self.setState({ burger: !this.state.burger }) }}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenu" className={"navbar-menu " + (this.state.burger ? "is-active" : "")} style={{ "background": "transparent" }}>
                <div className="navbar-end">
                  <div className="tabs is-right">
                    <ul>
                      <li className="is-active"><a href="#home">Home</a></li>
                      <li><a href="#features">Kaufen</a></li>
                    </ul>
                    <span className="navbar-item">
                      <LanguageSelector inline={true}></LanguageSelector>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body pt-6 pb-6">
          <div className="container has-text-centered">
            <h1 className="title">
              Ihr Escape Room Zugang
            </h1>
            <h2 className="subtitle">
              Mit einem Schlüssel zu unseren Escape Rooms öffnet sich Ihnen die Türe zu einem digitalen Abenteuer
            </h2>
          </div>
        </div>
      </section>,

<div className="box cta mb-0">
<p className="has-text-centered">
  <span className="tag is-primary">Einführungsangebot</span> Sparen Sie für eine beschränkte Zeit 50% beim Kauf unserer Escape Rooms.
</p>
</div>,
      <section className='container section' id="testimonials">
        <h1 className="title has-text-centered mb-6">
          Zugangsschlüssel für einen Escape Room kaufen
        </h1>
        <div className="columns is-centered  is-vcentered">
          <div className="column is-half">
            <article className="tile is-child card is-white">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src="/images/rooms/AztecTemplePeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">Raumzugang</p>
                    <p className="subtitle is-6">für eine Escape Room Session</p>
                    <div className='content'>
                      <ul>
                        <li>ungefähr 45-60 Minuten Spieldauer</li>
                        <li>für 2-8 Spieler (empfohlen 2-6)</li>
                        <li>Raumauswahl zusammen mit Spielern direkt vor Spielbeginn</li>
                        <li>Nutzung/Einlösung zu beliebigen Zeitpunkt</li>
                        <li>Zugangscode wird direkt nach der Buchung zugestellt</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-third">
            <h1 className="title is-1" style={{ "textAlign": "end" }}><span style={{ "fontSize": "1rem", "marginRight": "0.5rem" }}>nur </span>
              <span className='has-text-weight-light' style={{ "textDecoration": "line-through" }}>39,99€</span>
              <br />
              19,99€ <div style={{ "fontSize": "1rem" }}> pro Schlüssel </div><div style={{ "fontSize": "0.8rem", "fontStyle":"italic", "marginTop":"0.5rem" }}>Gemäß § 19 UStG enthält der<br/> Rechnungsbetrag keine Umsatzsteuer.</div></h1>
            <div className="field">
              <label className="label" style={{ "textAlign": "center" }}>Anzahl Schlüssel</label>
              <div className="control">
                <input className="input" type="number" min={1} max={10} value={this.state.numberOfKeys} onChange={this.onNumberOfKeysChange}/>
              </div>
            </div>
            <div className="notification is-light">
              E-Mail-Adresse und Rechnungsadresse werden im Bezahlvorgang abgefragt.
            </div>
            <CheckoutComponent onApprove={this.orderApproved} getNumberOfKeys={this.getNumberOfKeys}></CheckoutComponent>
          </div>
        </div>


      </section>
      ,
      <div className={"modal "+(this.state.orderApproved?"is-active":"")} style={{zIndex:1000}}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Kauf bestätigen</p>
            <button className="delete" aria-label="close" onClick={this.closeDialog}></button>
          </header>
          <section className="modal-card-body">
            <u>Rechnungsadresse:</u><br/>
            {this.state.name}<br/>
            {this.state.address[0]}<br/>
            {this.state.address[1]} {this.state.address[2]}
            <br/><br/>
            <u>Einkauf: </u><br/>
            {this.state.numberOfKeys}x Escape Party Session Schlüssel (à 19,99€)
            <br/><br/>
            <u>Gesamtwert: </u><br/>
            {this.state.total.toLocaleString("de-DE")}€ <br/><i>Gemäß § 19 UStG enthält der Rechnungsbetrag keine Umsatzsteuer.</i><br/>
            <br/>
            <a href="/widerrufsbelehrung.html">Widerrufsrecht</a><br/>

          </section>
          <footer className="modal-card-foot" style={{display:"flex", justifyContent:"end"}}>
            <button className="button is-success" onClick={this.finalizeOrder}>Kostenpflichtig bestellen</button>
          </footer>
        </div>
      </div>
      ,
      <div className={"modal "+(this.state.showInvoice?"is-active":"")} style={{zIndex:1000}}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Kauf erfolgreich</p>
            <button className="delete" onClick={this.closeDialog} aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className='content'>
            <p><b>Ihr(e) Escape Party Zugangsschlüssel lautet/lauten:</b></p>
            <pre style={{fontSize: "1.5rem", padding: "0.7rem 1rem"}}>{this.state.session_keys.map(k=><p>{k}</p>)}</pre>

            <h1>Rechnung Nr. {this.state.invoice_number}</h1>
            <div style={{"display": "flex", justifyContent: "space-between" }}>
              <div>
                <b>Nils Wenzler Informatik</b><br/>
                Maybachstraße 12<br/>
                71706 Markgröningen<br/>
                USt-IdNr. DE363739646<br/>
              </div>
            <p style={{textAlign:"right"}}><b>Rechnungs- und Lieferdatum:</b><br/> {this.state.invoice_date}</p>
            </div><br/>

            <b>Käufer:</b><br/>
            {this.state.name}<br/>
            {this.state.address[0]}<br/>
            {this.state.address[1]} {this.state.address[2]}<br/>
            
            <p>Vielen Dank für Ihr Vertrauen in die Nils Wenzler Informatik.
            Wir stellen Ihnen hiermit die folgende Leistung in Rechung:</p>

            <pre>{this.state.session_keys.length}x Escape Party Session Schlüssel (à 19,99€)</pre>
            <pre style={{textAlign:"right"}}>Summe Positionen: {19.99*this.state.session_keys.length}€<br/>
            nicht umsatzsteuerpflichtig nach §19 1 UStG<br/>
            <b>Rechnungsbetrag: {19.99*this.state.session_keys.length}€</b></pre>
            
            </div>
          </section>
          <footer className="modal-card-foot" style={{display:"flex", justifyContent:"end"}}>
            <button className="button is-success" onClick={this.closeDialog}>Fenster schließen</button>
          </footer>
        </div>
      </div>
      ,

      <section className="container section" id="rooms">
        <div className="intro column is-12 has-text-centered">
          <p className="subtitle">
            Mit einem Schlüssel zu Escape Party können Sie einen beliebigen Raum unserer Sammlung spielen.<br />
            Die Auswahl können Sie am Spieltag zusammen mit Ihren Mitstreitern treffen.
          </p>
        </div>
        <div className="sandbox mt-4">
          <div className="tile is-ancestor">
            <div className="tile is-parent is-shady">
              <article className="tile is-child card is-white">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src="/images/rooms/AztecTemplePeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">{getTranslationService().translate("lobby_room3")}</p>
                      <p className="subtitle is-6">{getTranslationService().translate("lobby_room3_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="tile is-parent is-shady">
              <article className="tile is-child card is-white">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src="/images/rooms/DungeonPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">{getTranslationService().translate("lobby_room1")}</p>
                      <p className="subtitle is-6">{getTranslationService().translate("lobby_room1_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="tile is-parent is-shady">
              <article className="tile is-child card is-white">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src="/images/rooms/SpaceshipPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">{getTranslationService().translate("lobby_room2")}</p>
                      <p className="subtitle is-6">{getTranslationService().translate("lobby_room2_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>,
      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column is-3 is-offset-2">
              <a className="is-inline-block" href="https://uluni.de">
                <img className="image" src="/images/uluni.svg" alt="" width="96px" /></a>

            </div>
            <div className="column is-3">
              <h2><strong>Nils Wenzler Informatik</strong></h2>
              <ul>
                <li><a href="/impressum.html">Impressum</a></li>
                <li><a href="/widerrufsbelehrung.html">Widerrufsbelehrung</a></li>
              </ul>
            </div>
            <div className="column is-4">
            </div>
          </div>

        </div>
        <script src="../js/bulma.js"></script>
      </footer>

    ];
  }

}


export default BuyPage;
