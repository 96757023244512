import './InspectThreeDialog.css';
import 'bulma/css/bulma.min.css';
import { Component, createRef } from 'react';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';
import { getRenderService } from './ThreeRenderService';


class InspectThreeDialog extends Component {
  constructor(props) {
    super(props);

    this.threeContainerRef = createRef();
    this.state = {};
    const self = this;

    this.close = this.close.bind(this);
    this.initialize = this.initialize.bind(this);
    this.animate = this.animate.bind(this);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidMount(){
    this.initialize();
  }

  componentWillUnmount(){
    this.renderer.forceContextLoss();
    this.threeContainerRef.current.innerHTML = "";
    this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;
    this.scene = undefined;
    this.stop = true;
  }

  initialize() {
    if(this.scene){
      return;
    }
    this.stop = false;
    
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )

    const clone = getRenderService().scene.getObjectByName(this.props.selectedObject).clone();
    clone.position.x = 0;
    clone.position.y = 0;
    clone.position.z = 0;
    clone.geometry = clone.geometry.clone();
    clone.geometry.center();
    this.scene.add(clone);

    var helper = new THREE.BoxHelper(clone);
    helper.geometry.computeBoundingSphere();
    this.camera.position.z = helper.geometry.boundingSphere.radius*4;

    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.threeContainerRef.current.appendChild(this.renderer.domElement)

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    /*this.controls = new TrackballControls( this.camera, this.renderer.domElement );

    this.controls.rotateSpeed = 2.0;
    this.controls.zoomSpeed = 1.2;
    this.controls.panSpeed = 0.8;

    this.controls.keys = [ 'KeyA', 'KeyS', 'KeyD' ];*/


    const ambientlight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(ambientlight);
    const fillLight1 = new THREE.DirectionalLight(0xff9999, 0.5);
    //fillLight1.position.set(- 1, 1, 2);

    this.scene.add(fillLight1);
    const fillLight2 = new THREE.DirectionalLight(0x8888ff, 0.2);
    fillLight2.position.set(0, - 1, 0);
    //this.scene.add(fillLight2);

    const directionalLight = new THREE.DirectionalLight(0xffffaa, 0.8);
    directionalLight.position.set(- 5, 10, - 5);
    this.scene.add(directionalLight);

    window.addEventListener('resize', this.onWindowResize, false)
    this.animate();
  }


  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  animate() {
    if(!this.stop){
      requestAnimationFrame(this.animate);
    }
    this.renderer.render(this.scene, this.camera);
    //this.resizeCanvasToDisplaySize();
    this.controls.update();

  }

  render() {
    let selection = undefined;
    if (this.state.selectedObject) {
      selection = this.state.selectedObject.object.name;
    }
    

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.close}></div>
        <div className='bottom-right-hint has-text-white'>
          <table >
            <tbody>
            <tr>
              <td className='pl-2'>{getTranslationService().translate("InspectExplanation")}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='inspect-three-container' ref={this.threeContainerRef}>

            </div>
        <div className="threedialog" onClick={(e) => { e.stopPropagation(); }}>
          <div style={{display: "inline-block"}} className='card'>
          <div className="card-content">
            <h1 className="title">{getTranslationService().translate(this.props.selectedObject)}</h1>
            

            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" style={{zIndex: 2}} onClick={this.close}></button>
      </div>
    );
  }

}

export default InspectThreeDialog;
