
import './FinalDialog.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';
import Confetti from 'react-confetti'
import { Crowded } from '../crowded';


class FinalDialog extends Component {
  constructor(props) {
    super(props);
    const self = this;

    this.state = {steamShutdown: false}

    this.close = this.close.bind(this);
    this.result = this.result.bind(this);
    this.shutdown = this.shutdown.bind(this);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  result() {

    if (this.props.onResult) {
      this.props.onResult(this.state.numbers);
    }
  }

  shutdown(){
    Crowded._sendMessage({ type: "shutdown", event: "shutdown"});
    this.setState({"steamShutdown": true})
  }

  render() {

    let gameState = getDataService().getGameState();
    let startTime = gameState.startTime+60*60*1000;


    let steamLeave = "";
    if(window.location.href.indexOf("steam")>=0){
      steamLeave = <a className={'button is-primary mt-6 is-fullwidth '+(this.state.steamShutdown?"is-loading":"")} onClick={this.shutdown}>{getTranslationService().translate("final_message_steam_shutdown")}</a>
    }

    let secondRound = "";
    if(gameState.successTime){
      let compareTime = gameState.successTime;
      let diffTime = startTime-compareTime;
      diffTime = Math.floor(diffTime/1000/60);
      debugger;
      if(diffTime >= 0 && window.location.href.indexOf("steam")<0){
        let newUrl = window.location.href.replace("escaperoom", "followuproom");
        let turl = new URL(newUrl);
        const url_params = new URLSearchParams(turl.search);
        url_params.set("startTime", gameState.startTime);
        newUrl = "/lobby/"+"?"+url_params.toString();

        secondRound = [<div dangerouslySetInnerHTML={{__html: getTranslationService().translate("final_message_second_round")}}></div>,<a className='button is-primary is-fullwidth' href={newUrl}>{getTranslationService().translate("lobby_escape_room")}</a>];
      }
      //clock.style.color = "#67cf28";
    }

    /*let title = "Buch";
    if(getTranslationService().translate(this.props.selectedObject)){
      title = getTranslationService().translate(this.props.selectedObject);
    }*/

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.close}>
          {<Confetti
            width={window.innerWidth}
            height={window.innerHeight}
    />}
        </div>
        <div className="card dialog" onClick={(e) => { e.stopPropagation(); }}>
          <div className="card-content">
            {/*<div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img src="/images/nils.png" alt="Nils Wenzler" />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-4">Nils Wenzler</p>
                <p className="subtitle is-6">@nilswenzler</p>
              </div>
          </div>*/}
                  
            <div className="content">
              <div dangerouslySetInnerHTML={{__html: getTranslationService().translate("final_message")}}></div>
              {secondRound}
              {steamLeave}
            </div>
          </div>
          {/*<footer className="card-footer">
            <a href="https://paypal.me/nilswenzler/5" target={"_blank"} className="card-footer-item">5 €</a>
            <a href="https://paypal.me/nilswenzler/10" target={"_blank"} className="card-footer-item has-text-white has-background-primary">10 €</a>
            <a href="https://paypal.me/nilswenzler/20" target={"_blank"} className="card-footer-item">20 €</a>
          </footer>*/}
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.close}></button>
      </div>
    );
  }

}

export default FinalDialog;
