import './Timer.css';
import 'bulma/css/bulma.min.css';
import { Component, createRef } from 'react';
import { getDataService } from './DataService';


class Timer extends Component {
  constructor(props) {
    super(props);

    this.timerRef = createRef();  
    this.state = { notifications: [] };

    //this.updateNotifications = this.updateNotifications.bind(this);

    getDataService().addCallback("gameevent", (ev)=>{
    });

  }

  componentDidMount(){
    
    const self = this;
    window.setInterval(()=>{
      let gameState = getDataService().getGameState();
      let startTime = gameState.startTime+60*60*1000;
      let compareTime = (new Date()).getTime();
      if(gameState.successTime){
        compareTime = gameState.successTime;
        if(!self.state.success){
          self.setState({success: true});
        }
        //clock.style.color = "#67cf28";
      }
      let clockString = "";
      if(compareTime > startTime){
        let tmp = compareTime;
        compareTime = startTime;
        startTime = tmp;
        clockString ="-";
      }
      let diffTime = startTime-compareTime;
      clockString+=Math.floor(diffTime/1000/60)+":";
      diffTime = diffTime%60000;
      if(diffTime/1000 < 10){
        clockString += "0";
      }
      clockString += Math.floor(diffTime/1000)+":";
      diffTime = diffTime%1000;
      if( Math.floor(diffTime/10) < 10){
        clockString += "0";
      }
      clockString += Math.floor(diffTime/10)+"";
      if(self.timerRef.current){
        self.timerRef.current.innerHTML = clockString;
      }
      
    },10);
  }

  
  render() {

    let link = "";
    const self = this;

    if(this.state.success){
      link = <div><a href="#" onClick={()=>{self.props.onShowMessage();}} style={{textAlign: "center", width: "100%"}}>Show message</a></div>
    }

    return (
      <div className="timer-container">
        <div className={'card timer-card '+(this.state.success?"has-background-success-light":"")}>
          <div className="card-content">
          <p className={"title is-4 is-family-monospace "+ (this.state.success?" has-text-success ":"")} ref={this.timerRef}>
          00:00:00
          </p>
          </div>
        </div>
      </div>
    );
  }

}

export default Timer;
