import './TableView.css';
import 'bulma/css/bulma.min.css';
import { Component, createRef } from 'react';
import { getDataService } from './DataService';
import { getRenderService } from './ThreeRenderService';
import { getTranslationService } from '../TranslationService';


class TableView extends Component {
  constructor(props) {
    super(props);

    this.timerRef = createRef();
    this.state = { notifications: [] };

    this.leave = this.leave.bind(this);

    getDataService().addCallback("gameevent", (ev) => {
    });

    this.updateHandles = {};

  }

  componentDidMount() {
    const self = this;
    window.addEventListener("contextmenu", e => e.preventDefault());

    getRenderService().addCallback("hover", (intersects, rs) => {
      if (intersects.length > 0) {

        let intersect = undefined;
        for (let i = 0; i < intersects.length; i++) {
          if (intersects[i].object.visible) {
            intersect = intersects[i];
            if(intersect.object.name.indexOf("Movable")>=0){
              rs.outlinePass.selectedObjects = [intersects[0].object];
              if(this.state.curSelectObject){
                rs.outlinePass.selectedObjects = [rs.scene.getObjectByName(this.state.curSelectObject.name) ];
              }else{
                rs.outlinePass.selectedObjects = [intersect.object ];
              }
            }
            break;
          }
        }
        if (!this.state.selectedObject || intersect.object.name != this.state.selectedObject.object.name) {
          this.setState({ selectedObject: intersect });
        }
        if (this.state.mouseDown === undefined) {
          for (let i of intersects) {
            if (i.object.name === "Table_Small") {
              this.setState({
                interactObject: intersect.object.name.indexOf("Movable") >= 0 ? intersect.object : undefined,
                tablePos: { x: i.point.x, y: undefined, z: i.point.z }
              });
              break;
            }
          }

          if (intersect.object.name.indexOf("Movable") >= 0) {
            document.body.style.cursor = "grab";
          } else {
            document.body.style.cursor = "inherit";
          }
        } else {
          if (this.state.mouseDown === 1 && this.state.interactObject) {
            for (let i of intersects) {
              if (i.object.name === "Table_Small") {
                this.state.interactObject.position.x = i.point.x + this.state.grabbingOffset.x;
                this.state.interactObject.position.z = i.point.z + this.state.grabbingOffset.z;

                const name = this.state.interactObject.name;
                let object = rs.scene.getObjectByName(name);
                if(object){
                  let id = getDataService().getPlayerId();
                  let state = {};
                  state[name] = {pos: {x: object.position.x, y: object.position.y, z: object.position.z}, rot: {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z}, movedBy: id};
                  if(this.updateHandles[name]){
                    clearTimeout(this.updateHandles[name]);
                  }
                  this.updateHandles[name] = window.setTimeout(()=>{
                    getDataService().updateGameState(state);
                  }, 50);
                }
                break;
              }
            }
          }
          if (this.state.mouseDown === 3 && this.state.lastObject) {
            for (let i of intersects) {
              if (i.object.name === "Table_Small") {
                const ang1 = Math.atan2(this.state.lastObject.position.x - this.state.tablePosStart.x, this.state.lastObject.position.z - this.state.tablePosStart.z);
                const ang2 = Math.atan2(this.state.lastObject.position.x - i.point.x, this.state.lastObject.position.z - i.point.z);

                this.state.lastObject.rotation.y = this.state.lastObjectRot - ang1 + ang2;

                const name = this.state.lastObject.name;
                let object = rs.scene.getObjectByName(name);
                if(object){
                  let id = getDataService().getPlayerId();
                  let state = {};
                  state[name] = {pos: {x: object.position.x, y: object.position.y, z: object.position.z}, rot: {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z}, movedBy: id};
                  if(this.updateHandles[name]){
                    clearTimeout(this.updateHandles[name]);
                  }
                  this.updateHandles[name] = window.setTimeout(()=>{
                    getDataService().updateGameState(state);
                  }, 50);
                }
                break;
              }
            }
          }
        }
      }

    });
    getRenderService().addCallback("mousedown", (ev, rs) => {
      self.setState({ mouseDown: ev.which });
      if (ev.which === 1) {
        if (this.state.selectedObject.object.name.indexOf("Movable") >= 0) {
          const newState = {curSelectObject: self.state.interactObject?self.state.interactObject:self.state.curSelectObject, grabbingOffset: {x: this.state.interactObject.position.x - this.state.tablePos.x, y: undefined, z: this.state.interactObject.position.z - this.state.tablePos.z}};
          self.setState(newState);
          console.log(newState);
          document.body.style.cursor = "grabbing";
        } else {
          document.body.style.cursor = "inherit";
        }
      }

      if (ev.which === 3) {
        ev.preventDefault();
        ev.stopPropagation();
        self.setState({tablePosStart: {x: self.state.tablePos.x, y: undefined, z: self.state.tablePos.z}, lastObjectRot: self.state.curSelectObject.rotation.y});
          
        document.body.style.cursor = "crosshair";
      }
    });

    getRenderService().addCallback("mouseup", (ev, rs) => {
      if(self.state.mouseDown === 1){
        self.setState({ mouseDown: undefined, lastObject: self.state.interactObject, lastObjectRot: (self.state.interactObject?self.state.interactObject.rotation.y:undefined) });
      }else{
        self.setState({ mouseDown: undefined});
      }
      document.body.style.cursor = "inherit";
      ev.preventDefault();
      ev.stopPropagation();
    });


  }

  leave() {
    if (this.props.onLeave) {
      this.props.onLeave();
    }
  }


  render() {

    return (
      <div className="table-view-container">
        <div className={'card'}>
          <div className="card-content">
            {getTranslationService().translate("TableViewLeftMouse")}<br />
            {getTranslationService().translate("TableViewRightMouse")} <br />
            <button className='button is-danger is-fullwidth mt-4' onClick={this.leave}>&#10132;</button>
          </div>
        </div>
      </div>
    );
  }

}

export default TableView;
