import './LandingPage.css';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from '../framework/ThreeRenderService'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import { getTranslationService } from '../TranslationService';
import LanguageSelector from '../framework/LanguageSelector';
import PlayerRepresentation from '../framework/PlayerRepresentation';
import { getDataService } from '../framework/DataService';
import {Crowded} from '../crowded';

class LandingPage extends Component {
  constructor(props) {
    super(props);

    
    this.animations = {};
    this.outfits = {};
    getRenderService().addToDom("three-container");
    getRenderService().freeCamera = true;
    getRenderService().enablePointerLock = false;
    getRenderService().enableKeyboardControls = false;
    this.mounted = false;

    this.state = { users: {}, connected: false, loading: false, session_key:""};

    this.loadOutfit = this.loadOutfit.bind(this);
    this.createPlayerIfLoaded = this.createPlayerIfLoaded.bind(this);
    this.animationPlayback = this.animationPlayback.bind(this);
  }

  createPlayerIfLoaded(){
    /*if(!this.outfits[1] ||!this.outfits[2] ||!this.outfits[3] || !this.animations["idle"] || !this.animations["walking"] || !this.animations["dance"] || !this.animations["right"] || !this.animations["backward"]){
        setTimeout(this.createPlayerIfLoaded, 1000);
      return;
    }
    for(let i=1; i<=3; i++){
      let group = new THREE.Group();
      this.player = SkeletonUtils.clone(this.playerPrefab);

      this.player.traverse(child => {
        if (child.material) child.material = this.outfits[i];
      });
      
      this.player.position.y = 0.08;
      this.player.position.x = -0.7+i*1.3;
      this.player.position.z = -0.9;
      if(i==2){
        this.player.position.z = -0.25;
      }

      const mixer = new THREE.AnimationMixer(this.player );
      
      const idleAction = mixer.clipAction(this.animations["idle"]);
      const walkAction = mixer.clipAction(this.animations["walking"]);
      const danceAction = mixer.clipAction(this.animations["dance"]);
      const rightAction = mixer.clipAction(this.animations["right"]);
      const backwardAction = mixer.clipAction(this.animations["backward"]);
      danceAction.play();
      mixer.setTime(i*0.1);
      group.add(this.player);
      getRenderService().scene.add(group);

      getRenderService().addCallback("scenetick", (timedif, rs) => {
        mixer.update(timedif*0.8);
      });
    }*/
  }

  componentDidMount() {

    if (this.mounted) {
      return;
    }



    this.mounted = true;
    const self = this;

    window.onmousemove = function(e){
      console.log("mouse location:", e.clientX/window.innerWidth-0.5, e.clientY/window.innerHeight-0.5);
      let x = (e.clientX/window.innerWidth-0.5)*4;
      let y = e.clientY/window.innerHeight-0.5;
      getRenderService().camera.position.z = 3.2;
      getRenderService().camera.position.x = (getRenderService().camera.position.x*10+(2.0+x))/11;
      getRenderService().camera.position.y = 1.6;
      //getRenderService().camera.rotation.y = 0;
      //getRenderService().camera.rotation.x = -0.22;
      getRenderService().camera.lookAt( 2.00, 1, 0 );
    }
    
    function handleOrientation(event) {
      const alpha = event.alpha;
      const beta = event.beta;
      const gamma = event.gamma;
      let x = gamma/180*2;
      getRenderService().camera.position.z = 3.2;
      getRenderService().camera.position.x = 2.0+x;
      getRenderService().camera.position.y = 1.6;
      //getRenderService().camera.rotation.y = 0;
      //getRenderService().camera.rotation.x = -0.22;
      getRenderService().camera.lookAt( 2.00, 1, 0 );
    }

    window.addEventListener('deviceorientation', handleOrientation);


    setTimeout(this.createPlayerIfLoaded, 1000);


    getRenderService().camera.position.z = 3.2;
    getRenderService().camera.position.x = 2.0;
    getRenderService().camera.position.y = 1.6;
    getRenderService().camera.rotation.y = 0;
    getRenderService().camera.rotation.x = -0.22;

    const loader2 = new FBXLoader();
    loader2.load('/stickfigure_3.fbx', (fbx) => {
      //fbx = fbx.children[0];
      fbx.scale.setScalar(0.011);
      fbx.traverse(child => {
        if (child.isMesh) child.geometry.computeVertexNormals()
      });

      self.loadOutfit('/stickfigure_2_uv_char1.png', 3);
      self.loadOutfit('/stickfigure_2_uv_char2.png', 2);
      self.loadOutfit('/stickfigure_2_uv_char3.png', 4);
      self.loadOutfit('/stickfigure_2_uv_char4.png', 1);

      self.playerPrefab = fbx;

      const animIdle = new FBXLoader();
      animIdle.load('/Anim_Idle.fbx', (anim) => {
        self.animations["idle"] = anim.animations[0];
      });
      const animMove = new FBXLoader();
      animMove.load('/Anim_Walking.fbx', (anim) => {
        self.animations["walking"] = anim.animations[0];
      });
      const animBackwards = new FBXLoader();
      animBackwards.load('/Anim_Walking_Backwards.fbx', (anim) => {
        self.animations["backward"] = anim.animations[0];
      });
      const animLeft = new FBXLoader();
      animLeft.load('/Anim_HipHopDance.fbx', (anim) => {
        self.animations["dance"] = anim.animations[0];
      });
      const animRight = new FBXLoader();
      animRight.load('/Anim_Walking_Right.fbx', (anim) => {
        this.animations["right"] = anim.animations[0];
      });
    });

    const loader = new GLTFLoader();
    this.dungeonScene = undefined;
    this.spaceScene = undefined;

    loader.load('/space_scene_lobby.glb', (gltf) => {

      this.spaceScene = gltf.scene;
      getRenderService().scene.add(gltf.scene);
      getRenderService().listener.setMasterVolume(0);

      gltf.scene.traverse(child => {
        if (child.type == "PointLight") {
          child.intensity /= 400;
        }
        if (child.name === "Chest_Open") {
          child.visible = false;
        }
        if (child.name === "Scroll_Chest") {
          child.visible = false;
        }
        if (child.isMesh) {
          if (child.material) {
            child.material.roughness = 1;
            child.material.metalness = 0;
          }
        }

      });


      fetch("lobby_replay.txt")
      .then(response => response.text())
      .then(data => {
              self.playback = data.split(/\r?\n|\r|\n/g);;
              self.startTime = 26000;
              self.lastTick = (new Date()).getTime();
              self.playbackTime = self.startTime;
              self.playbackIndex = 0;
              window.setInterval(self.animationPlayback, 10);
          });
     
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);
        let loginTargetPos = new THREE.Vector3(0, 0, 0);
        /*getRenderService().addCallback("scenetick", (timediff, rs) => {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          const numberOfPlayers = Object.values(this.state.users).filter(u => !u._data.offline).length;
          loginTargetPos.x = rs.camera.position.x;
          loginTargetPos.y = rs.camera.position.y;
          loginTargetPos.z = rs.camera.position.z;


          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(loginTargetPos, 3 * timediff);
        });*/

    });

  }

  animationPlayback(){
    let rerun = true;
    this.playbackTime += (new Date()).getTime()-this.lastTick;
    this.lastTick = (new Date()).getTime();
    while(rerun){
      let curMsg = JSON.parse(this.playback[this.playbackIndex]);
      if(curMsg.t<this.playbackTime){
        this.playbackIndex++;
      }else{
        rerun = false;
      }
      if(this.playbackIndex >= this.playback.length-3){
        this.playbackIndex = 0;
        this.playbackTime = this.startTime;
      }
      Crowded._parseMessage(JSON.parse(curMsg.m));
    }
  }

  loadOutfit(outfit, index) {
    const loader = new THREE.TextureLoader();
    const self = this;
    // load a resource
    loader.load(
      // resource URL
      outfit,

      // onLoad callback
      function (texture) {
        // in this example we create the material when the texture is loaded
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;
        const material = new THREE.MeshPhongMaterial({
          map: texture,
          color: 0x999999,
          shininess: 0
        });
        self.outfits[index] = material;
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (err) {
        console.error('An error happened.');
      }
    );
  }

  

  render() {
    const self = this;

    return (
      <div key="main_container" className='mainContainer'>
        <PlayerRepresentation drawMe={1} />
        <LanguageSelector></LanguageSelector>
        <div style={{ flexGrow: 1 }}></div>
        <div className='login-center'>
        <div className='logo-image'></div>
        
          <div style={{ flexGrow: 1, flexShrink: 0, height: "22rem", maxHeight: "40vh"}}></div>
          <section className="section card">
      <div className="container">
        <h2 className="mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">{getTranslationService().translate("landing_title")}</h2>
            <p className="subtitle has-text-grey mb-5">
            {getTranslationService().translate("landing_subtitle1")}<br/>
            {getTranslationService().translate("landing_subtitle2")}
            </p>
            <div className="buttons is-centered">
              <a className="mr-3" href="/login">
                <div className="button is-primary" style={{background: "#269463"}}>{getTranslationService().translate("landing_enter_room")}</div></a>
            
        </div>
        <div className="columns is-multiline is-justify-content-space-between">
          <div className="column is-12 is-5-desktop">
            <div className="mb-6 is-flex">
              <div className='feature-icon'>
              <i className="material-icons-outlined">view_in_ar</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_3d_title")}</h4>
                <p className="subtitle has-text-grey">
                  {getTranslationService().translate("landing_3d_desc")}
                </p>
              </div>
            </div>
            <div className="mb-6 is-flex">
              <div className='feature-icon'>
              <i className="material-icons-outlined">file_download_off</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">
                  {getTranslationService().translate("landing_no_installation_title")}</h4>
                <p className="subtitle has-text-grey">
                {getTranslationService().translate("landing_no_installation_desc")}
                </p>
              </div>
            </div>
            <div className="mb-6 is-flex">
              <div className='feature-icon'>
              <i className="material-icons-outlined">view_carousel</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">
                  {getTranslationService().translate("landing_choice_of_rooms")}</h4>
                <p className="subtitle has-text-grey">
                {getTranslationService().translate("landing_choice_of_rooms_desc")}
                </p>
              </div>
            </div>
          </div>
          <div className="column is-12 is-5-desktop">
            <div className="mb-6 is-flex">
            <div className='feature-icon'>
              <i className="material-icons-outlined">public</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_location_independent_title")}</h4>
                <p className="subtitle has-text-grey">
                {getTranslationService().translate("landing_location_independent_desc")}
                </p>
              </div>
            </div>
            <div className="mb-6 is-flex">
            <div className='feature-icon'>
              <i className="material-icons-outlined">timer</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_any_time_title")}</h4>
                <p className="subtitle has-text-grey">{getTranslationService().translate("landing_any_time_desc")}</p>
              </div>
            </div>
            <div className="mb-6 is-flex">
              <div className='feature-icon'>
                <i className="material-icons-outlined">groups</i>
              </div>
              <div className="ml-3">
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_any_grou_size_title")}</h4>
                <p className="subtitle has-text-grey">{getTranslationService().translate("landing_any_grou_size_desc")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section " style={{background: "#269463"}}>
      <div className="container">
        <div className="is-vcentered columns is-multiline">
          <div className="column is-6 is-5-desktop mb-4">
            <h2 className="has-text-white mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold">
               {getTranslationService().translate("landing_teaser_title")}
            </h2>
            <p className="has-text-white">
            {getTranslationService().translate("landing_teaser_desc")}
            </p>
          </div>
          <div className="column is-5 ml-auto">
          </div>
        </div>
        
        <div className='mt-4'>
            <form action="#">
                <a href="/login"><div className="button is-secondary ">{getTranslationService().translate("landing_enter_room")}</div></a>
            </form>
            </div>
      </div>
    </section>
    <footer className="section">
      <div className="container">
        <div className="pb-5 is-flex is-flex-wrap-wrap is-justify-content-between is-align-items-center">
          <div className="mr-auto mb-2">
            <a className="is-inline-block" href="https://uluni.de">
              <img className="image" src="/images/uluni.svg" alt="" width="96px"/></a>
          </div>
          <div>
            <ul className="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center">
              <li className="mr-4"><a className="button is-text" href="/impressum.html">Impressum</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-5" style={{borderTop: "1px solid #dee2e6"}}></div>
      <div className="container">
        <div className="is-flex-tablet is-justify-content-between is-align-items-center">
          <p>All rights reserved © Nils Wenzler 2022</p>
          <div className="py-2 is-hidden-tablet"></div>
          <div className="ml-auto">
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/facebook.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="https://twitter.com/uluni_games" target="_blank">
              <img src="/images/app-page/socials/twitter.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/github.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/instagram.svg" alt=""/></a>
            <a className="is-inline-block" href="#">
              <img src="/images/app-page/socials/linkedin.svg" alt=""/></a>
          </div>
        </div>
      </div>
    </footer>
          <div style={{ flexGrow: 1 }}></div>
        </div>

        <div style={{ flexGrow: 1 }}></div>
      </div>
      
    );
  }

}


export default LandingPage;
