import './MenuOverlay.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';
import LanguageSelector from './LanguageSelector';
import VolumeControl from './VolumeControl';
import { getRenderService } from './ThreeRenderService';


class MenuOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = { numbers: [0, 0, 0] };
    const self = this;

    this.close = this.close.bind(this);
    this.nextHint = this.nextHint.bind(this);
  }

  close() {
    if(getRenderService().listener){
      getRenderService().listener.context.resume();
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  nextHint(e) {
    e.stopPropagation();
    const hints = this.props.gamestate.hints ? this.props.gamestate.hints : [];
    const nextHint = this.props.hints.findIndex((h,i) => !h.solved && hints.findIndex(e => e === i)<0 );
    if (nextHint >= 0) {
      hints.push(nextHint);
      getDataService().updateGameState({ hints: hints });
    }
  }

  render() {
    let selection = undefined;
    if (this.state.selectedObject) {
      selection = this.state.selectedObject.object.name;
    }

    let hints = [];
    if (this.props.gamestate && this.props.gamestate.hints) {
      for (let hint of this.props.gamestate.hints) {
        hints.unshift(<div key={"hint"+hint} className="notification mb-4">
          {this.props.hints[hint].hint}
        </div>)
      }
    }

    let showMessage = "";
    if(this.props.gamestate && this.props.gamestate.successTime){
      showMessage = <button className='button is-fullwidth mb-4 is-primary is-large' onClick={this.props.onShowMessage}>{getTranslationService().translate("Nachricht anzeigen")}</button>;
    }

    let loading="";
    if(this.props.loading){
      loading = <div className="loader-wrapper">
      <p className="title is-4">Loading room ...</p>
      <p className="subtitle is-6">This may take a while </p>
        <div id="loaderSpinner" className="loader is-loading"></div>

      </div>;
      return loading;
    }

      //<i className="fas fa-volume-off"></i>
    return (
      <div className="modal is-active" >
        <div style={{position: "fixed", top:"6rem", zIndex: 5, right: 0, textAlign: "right"}}>
          <div style={{background: "white", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius: "0.25rem", display: "inline-block"}}>
            <LanguageSelector inline={true}></LanguageSelector>
          </div>
          <br/>
          <div style={{background: "white", marginTop: "1rem", padding: "0.5rem 0.8rem", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius: "0.25rem", display: "inline-block"}}>
            <VolumeControl></VolumeControl>
          </div>
          
        </div>
        <div className="modal-background" onClick={this.close}></div>
        <div className="card dialog" style={{zIndex: 10}} onClick={(e) => { e.stopPropagation(); }}>
          <div className="card-content">
            {showMessage}
            <button className='button is-fullwidth is-primary is-large' style={{background: "#269463"}} onClick={this.close}>{getTranslationService().translate("Zum Escaperoom")}</button>
            <button className='button is-fullwidth mt-4' onClick={(e) => this.nextHint(e)}>{getTranslationService().translate("Hinweis anfordern")}</button>
            <div className='hint-container mt-4'>
              {hints}
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.close}></button>
      </div>
    );
  }

}

export default MenuOverlay;
