import { getDataService } from './DataService';
import { getRenderService } from './ThreeRenderService';
import * as THREE from 'three';
import { Octree } from './Octree';

export class Object {
    constructor(threeObject, room) {
        this.threeObject = threeObject;
        this.uuid = threeObject.cUuid;
    }

    getTranslation(){
        return "";
    }

    interaction(room){
        return undefined;
    }

    interactStart(room){}
    interactEnd(room){}

    tick(room, timediff){}

    onStateChange(stateChange, room){}
    onGlobalStateChange(stateChange, room){}

    interactable(){
        return true;
    }
}

export class Door extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        let params = threeObject.name.split("_");
        this.triggerState = params[1];
        this.tarRotate = parseFloat(params[2]);
        this.closed = true;

        let oct = new Octree();
        oct.fromGraphNode(threeObject);
        getRenderService().addOctree(threeObject.name, oct);
        threeObject.no_collision = true;
    }

    interaction(room){ 
        return "door";
    }

    interactStart(room){
        let newState = {};
        newState[this.triggerState] = true;
        getDataService().updateGameState(newState);
    }

    onGlobalStateChange(stateChange, room){
        if(stateChange[this.triggerState] && this.closed){
            this.closed = false;
            let init = this.threeObject.rotation.y;
            this.threeObject.rotation.y = this.tarRotate;
            //getSoundService().playSound(this.threeObject.name);
            let oct = getRenderService().getOctree(this.threeObject.name);
            if(oct){
                oct.clear();
                this.threeObject.no_collision = false;
                oct.fromGraphNode(this.threeObject);
                this.threeObject.no_collision = true;
            }
            
            this.threeObject.rotation.y = init;
        }
    }

    onStateChange(stateChange, room){
        console.log(this.uuid, stateChange);
        this.item = stateChange.item;
        this.updateRepresentation(room);
    }

    updateRepresentation(room){
    }

    tick(room, timediff){
        if(!this.closed){
            this.threeObject.rotation.y = (1-timediff)*this.threeObject.rotation.y+this.tarRotate*timediff;
        }
    }

    getTranslation(){
        return "Door";
    }
}

export class Cupboard extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        let oct = new Octree();
        oct.fromGraphNode(threeObject);
        getRenderService().addOctree(threeObject.name, oct);
        threeObject.no_collision = true;
        this.open = false;
        let params = threeObject.name.split("_");
        this.tarOffsetX = parseFloat(params[1]);
        this.tarOffsetY = parseFloat(params[2]);
        this.tarOffsetZ = parseFloat(params[3]);

        for(let child of this.threeObject.children){
            child.initialPosition = child.position.clone();
        }
    }

    interaction(room){ 
        return "door";
    }

    interactStart(room){
        let state = getDataService().getGameState();
        let open = false;
        if(state[this.uuid]){
            open = state[this.uuid]["open"];
        }

        let newState = {};
        newState[this.uuid] = {open: !open};
        getDataService().updateGameState(newState);
    }

    onGlobalStateChange(stateChange, room){
    }

    onStateChange(stateChange, room){
        if(stateChange["open"]){
            this.open = true;
        }else{
            this.open = false;
        }
    }

    updateRepresentation(room){
    }

    tick(room, timediff){
        if(this.open){
            for(let child of this.threeObject.children){
                child.position.x = (1-timediff)*child.position.x+(this.tarOffsetX+child.initialPosition.x)*timediff;
                child.position.y = (1-timediff)*child.position.y+(this.tarOffsetY+child.initialPosition.y)*timediff;
                child.position.z = (1-timediff)*child.position.z+(this.tarOffsetZ+child.initialPosition.z)*timediff;
            }
        }else{
            for(let child of this.threeObject.children){
                child.position.x = (1-timediff)*child.position.x+(child.initialPosition.x)*timediff;
                child.position.y = (1-timediff)*child.position.y+(child.initialPosition.y)*timediff;
                child.position.z = (1-timediff)*child.position.z+(child.initialPosition.z)*timediff;
            }
        }
    }

    getTranslation(){
        return "Cupboard";
    }
}

export class StoringSpot extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        this.item = undefined;
        this.itemObject = undefined;
        this.threeObject.material = this.threeObject.material.clone();
    }

    interaction(room){ 
        let me = getDataService().getMe();
        let carrying = me._data.state.carrying;
        if(carrying && !this.item){
            return "storingSpot";
        }
        if(!carrying && this.item){
            return "pickupContainer";
        }
        if(carrying && this.item){
            if(!this.item[carrying.type]){
                if(this.item.type == "KebabBread"){
                    if(carrying.type == "SaladCut" || carrying.type == "OnionCut" || carrying.type == "KebabMeatCut" || carrying.type == "RedCabbageCut"  || carrying.type == "TomatoCut"){
                        return "combine";
                    }
                }
            }
        }
        return undefined;
    }

    interactStart(room){
        let me = getDataService().getMe();
        let carrying = me._data.state.carrying;
        if(carrying && !this.item){
            let newItem = carrying;
            me.updateState({ carrying: null });
            let newState = {};
            newState[this.uuid] = {item: newItem};
            getDataService().updateGameState(newState);
        }else if(!carrying && this.item){
            me.updateState({ carrying: this.item });
            let newState = {};
            newState[this.uuid] = {item: null};
            getDataService().updateGameState(newState);
        }else if(carrying && this.item){
            if(!this.item[carrying.type]){
                if(this.item.type == "KebabBread"){
                    if(carrying.type == "SaladCut" || carrying.type == "OnionCut" || carrying.type == "KebabMeatCut" || carrying.type == "RedCabbageCut"  || carrying.type == "TomatoCut"){
                        let newState = {};
                        newState[this.uuid] = {item: this.item};
                        newState[this.uuid].item[carrying.type] = 1;
                        getDataService().updateGameState(newState);
                        me.updateState({ carrying: null });
                    }
                }
            }
        }
    }

    
    onStateChange(stateChange, room){
        console.log(this.uuid, stateChange);
        this.item = stateChange.item;
        this.updateRepresentation(room);
    }

    updateRepresentation(room){
        console.log("updateRepresentation");
        if(this.item && !this.itemObject){
            let threeObjectItem = room.models[this.item?this.item.type:undefined];
            this.itemObject = threeObjectItem.clone();

            this.itemObject.position.x = 0;
            this.itemObject.position.y = 0;
            this.itemObject.position.z = 0;

            this.itemObject.visible = true;

            this.threeObject.add(this.itemObject);
        }
        if(!this.item && this.itemObject){
            this.threeObject.remove(this.itemObject);
            this.itemObject = undefined;
        }
        if(this.threeObject){
            this.threeObject.traverse(obj => {
                var withNoDigits = obj.name.replace(/Prop_/g, '').replace(/[0-9]/g, '');
                if(obj.name.indexOf("Prop_")>=0){
                    obj.visible = this.item[withNoDigits]?true:false;
                }
            })
        }
    }

    tick(room, timediff){
        let me = getDataService().getMe();
        if(me && me._data.state.pos){
            let pos = me._data.state.pos;
            let distance = Math.sqrt(Math.pow(pos.x-this.threeObject.position.x,2)+Math.pow(pos.z-this.threeObject.position.z,2));
            let targetOpacity = 1/distance;
            if(!me._data.state.carrying){
                targetOpacity = 0;
            }
            this.threeObject.material.opacity = (this.threeObject.material.opacity*10 + targetOpacity)/11;
        }
    }

    getTranslation(){
        return "StoringSpot";
    }
}

export class TrashCan extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
    }

    interactStart(room){
        let me = getDataService().getMe();
        me.updateState({ carrying: null });
    }

    interaction(room){ 
        let me = getDataService().getMe();
        if(me._data.state.carrying){
            return "trashCan";
        }
        return undefined;
    }

    getTranslation(){
        return "TrashCan";
    }
}

export class Container extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        this.thing = threeObject.name.substring(threeObject.name.indexOf("_")+1);
    }

    interactStart(room){
        let me = getDataService().getMe();
        if(!me._data.state.carrying){
            me.updateState({ carrying: {type: this.thing} });
        }
    }

    interaction(room){ 
        let me = getDataService().getMe();
        if(!me._data.state.carrying){
            return "pickupContainer";
        }
        return undefined;
    }

    getTranslation(){
        return this.thing + "Container";
    }
}

export class DonerKebabSpot extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        for(let child of threeObject.children){
            if(child.name == "KebabSkewer"){
                this.kebabSkewer = child;
            }
            if(child.name == "KebabMeat"){
                this.kebabMeat = child;
            }
            if(child.name == "KebabMeatCut"){
                this.kebabMeatCut = child;
            }
        }
        this.cutting = undefined;
        this.kebabMeatCut.visible = false;
        this.meat = false;
    }

    onStateChange(stateChange, room){
        this.meat = stateChange.meat?true:false;
        this.kebabMeatCut.visible = this.meat;
    }

    interactStart(room){
        let me = getDataService().getMe();
        if(!me._data.state.carrying){
            // TODO kebab
            if(this.meat){
                let me = getDataService().getMe();
                me.updateState({ carrying: {type: "KebabMeatCut"} });
                let newState = {};
                newState[this.uuid] = {meat: false};
                getDataService().updateGameState(newState);
            }else{
                this.cutting = 0;
            }
        }
    }

    interactEnd(room){
        this.cutting = undefined;
        room.setState({progressBar: undefined});
    }

    interaction(room){ 
        let me = getDataService().getMe();
        if(this.cutting != undefined){
            return "cuttingProgress";
        }
        if(!me._data.state.carrying){
            // todo
            return "cuttingSpot";
        }
        return undefined;
    }

    tick(room, timediff){
        this.kebabSkewer.rotation.y -= timediff * 0.1;
        this.kebabMeat.rotation.y -= timediff * 0.1;

        if(this.cutting != undefined){
            this.cutting = Math.min(1,this.cutting+timediff/2);
            if(this.cutting == 1){
                this.cutting = undefined;
                let newState = {};
                newState[this.uuid] = {meat: true};
                getDataService().updateGameState(newState);
                room.setState({progressBar: undefined});
            }else{
                room.setState({progressBar: this.cutting});
            }
        }
    }

    getTranslation(){
        return "DonerKebabSpot";
    }
}

export class CuttingSpot extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        this.item = undefined;
        this.itemObject = undefined;
        this.cutting = undefined;
    }

    interaction(room){ 
        if(this.cutting != undefined){
            return "cuttingProgress";
        }
        let me = getDataService().getMe();
        if(me._data.state.carrying && !this.item){
            return "storingSpot";
        }
        if(this.item && this.item.type.indexOf("Cut")>=0){
            return "pickupContainer";
        }
        if(!me._data.state.carrying && this.item){
            return "cuttingSpot";
        }
        return undefined;
    }

    interactStart(room){
        let me = getDataService().getMe();
        if(me._data.state.carrying && !this.item){
            let newItem = me._data.state.carrying;
            me.updateState({ carrying: null });
            let newState = {};
            newState[this.uuid] = {item: newItem};
            getDataService().updateGameState(newState);
        }else if(!me._data.state.carrying && this.item){
            if(this.item.type.indexOf("Cut")>=0){
                me.updateState({ carrying: this.item });
                let newState = {};
                newState[this.uuid] = {item: null};
                getDataService().updateGameState(newState);
            }else if(this.cutting == undefined){
                this.cutting = 0;
            }
        }
    }

    interactEnd(room){
        this.cutting = undefined;
        room.setState({progressBar: undefined});
    }

    
    onStateChange(stateChange, room){
        if(this.item != stateChange.item){
            
            this.item = undefined;
            this.updateRepresentation(room);
        }
        this.item = stateChange.item;
        this.updateRepresentation(room);
    }

    updateRepresentation(room){
        if(this.item && !this.itemObject){
            let threeObjectItem = room.models[this.item?this.item.type:undefined];
            this.itemObject = threeObjectItem.clone();

            this.itemObject.position.x = 0;
            this.itemObject.position.y = 0;
            this.itemObject.position.z = 0;

            this.itemObject.visible = true;

            this.threeObject.add(this.itemObject);
        }
        if(!this.item && this.itemObject){
            this.threeObject.remove(this.itemObject);
            this.itemObject = undefined;
        }
    }

    tick(room, timediff){
        if(this.cutting != undefined){
            this.cutting = Math.min(1,this.cutting+timediff/2);
            if(this.cutting == 1){
                this.cutting = undefined;
                let newState = {};
                newState[this.uuid] = {item: {type: this.item.type+"Cut"}};
                getDataService().updateGameState(newState);
                room.setState({progressBar: undefined});
            }else{
                room.setState({progressBar: this.cutting});
            }
        }
    }

    getTranslation(){
        return "CuttingSpot";
    }
}

export class CombinationSpot extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        this.item = undefined;
        this.itemObject = undefined;
        this.threeObject.material = this.threeObject.material.clone();
        this.allowed = ["OnionCut", "SaladCut", "RedCabbageCut", "TomatoCut"];
    }

    interaction(room){ 
        let me = getDataService().getMe();
        let type = me._data.state.carrying?me._data.state.carrying.type:undefined;
        if(this.allowed.find((e)=>e == type) && !this.item){
            return "storingSpot";
        }
        if(!me._data.state.carrying && this.item){
            return "pickupContainer";
        }
        return undefined;
    }

    interactStart(room){
        let me = getDataService().getMe();
        let type = me._data.state.carrying?me._data.state.carrying.type:undefined;
        if(this.allowed.find((e)=>e == type) && !this.item){
            let newItem = me._data.state.carrying;
            me.updateState({ carrying: null });
            let newState = {};
            newState[this.uuid] = {item: newItem};
            getDataService().updateGameState(newState);
        }else if(!me._data.state.carrying && this.item){
            me.updateState({ carrying: this.item });
            let newState = {};
            newState[this.uuid] = {item: null};
            getDataService().updateGameState(newState);
        }
    }

    
    onStateChange(stateChange, room){
        this.item = stateChange.item;
        this.updateRepresentation(room);
    }

    updateRepresentation(room){
        if(this.item && !this.itemObject){
            let threeObjectItem = room.models[this.item?this.item.type:undefined];
            this.itemObject = threeObjectItem.clone();

            this.itemObject.position.x = 0;
            this.itemObject.position.y = 0;
            this.itemObject.position.z = 0;

            this.itemObject.visible = true;

            this.threeObject.add(this.itemObject);
        }
        if(!this.item && this.itemObject){
            this.threeObject.remove(this.itemObject);
            this.itemObject = undefined;
        }
    }

    tick(room, timediff){
        let me = getDataService().getMe();
        if(me && me._data.state.pos){
            let pos = me._data.state.pos;
            let distance = Math.sqrt(Math.pow(pos.x-this.threeObject.position.x,2)+Math.pow(pos.z-this.threeObject.position.z,2));
            let targetOpacity = 1/distance;
            let type = me._data.state.carrying?me._data.state.carrying.type:undefined;
            if(!this.allowed.find((e)=>e == type) || this.item){
                targetOpacity = 0;
            }
            this.threeObject.material.opacity = (this.threeObject.material.opacity*10 + targetOpacity)/11;
        }
    }

    getTranslation(){
        return "StoringSpot";
    }
}

export class HoldingRepresentation extends Object {
    constructor(threeObject, room) {
        super(threeObject, room);
        this.threeObject = undefined;
    }

    interaction(room){ 
        
        return undefined;
    }

    interactStart(room){
        
    }

    
    onStateChange(stateChange, room){
    }

    tick(room, timediff){
        let me = getDataService().getMe();
        let carrying = me._data.state.carrying;
        if(me && me._data.state){
            let type = me._data.state.carrying?me._data.state.carrying.type:undefined;
            let threeObjectItem = room.models[type];
            if(threeObjectItem && this.threeObject == undefined){
                this.threeObject = threeObjectItem.clone();

                this.threeObject.visible = true;

                getRenderService().scene.add(this.threeObject);
            }
            if(this.threeObject && !me._data.state.carrying){
                getRenderService().scene.remove(this.threeObject);
                this.threeObject = undefined;
            }
            
        }

        if(this.threeObject){
            let zVec = new THREE.Vector3( 0, 0.4, 0 );
            let quat = new THREE.Quaternion();
            quat.setFromEuler(getRenderService().camera.rotation);

            zVec.applyQuaternion( quat );
            this.threeObject.rotation.y = getRenderService().camera.rotation.y;

            this.threeObject.position.x = getRenderService().camera.position.x+zVec.x;
            this.threeObject.position.y = getRenderService().camera.position.y-1+zVec.y;
            this.threeObject.position.z = getRenderService().camera.position.z+zVec.z;

            if(carrying){
                this.threeObject.traverse(obj => {
                    var withNoDigits = obj.name.replace(/Prop_/g, '').replace(/[0-9]/g, '');
                    if(obj.name.indexOf("Prop_")>=0){
                        obj.visible = carrying[withNoDigits]?true:false;
                    }
                })
            }
        }
    }

    getTranslation(){
        return "StoringSpot";
    }

    interactable(){
        return false;
    }
}