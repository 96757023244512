import {Crowded} from '../crowded';

let _instance = undefined;

export function getDataService() {
    if (!_instance) {
        _instance = new DataService();
    }
    return _instance;
}

class DataService {

    constructor() {
        _instance = this;

        this.callbacks = {
            "gamestate": [],
            "gameevent": [],
            "userstate": []
        };

        Crowded.ReadyForCommunication(()=>{});
        
        Crowded.addEventListener("game_update_state", ()=>{console.log(Crowded.getGameState()); this.triggerCallback("gamestate", Crowded.getGameState());});
        Crowded.addEventListener("sync", ()=>{this.triggerCallback("gamestate", Crowded.getGameState());this.triggerCallback("userstate", Crowded.getGameState());});
        Crowded.addEventListener("game_update_event", (ev)=>{this.triggerCallback("gameevent", ev)});
        Crowded.addEventListener("user_update_state", ()=>{this.triggerCallback("userstate", Crowded.getGameState());})
        //this.updateHover = this.updateHover.bind(this);
    }

    addCallback(type, func){
        if(this.callbacks[type]){
            this.callbacks[type].push(func);
        }
    }

    triggerCallback(type, data){
        if(this.callbacks[type]){
            for(let cb of this.callbacks[type]){
                cb(data, this);
            }
        }
    }

    updateGameState(newState){
        Crowded.updateGameState(newState);
    }

    getGameState(){
        return Crowded.getGameState();
    }

    getUsers(){
        return Crowded.getUsers();
    }

    triggerGameEvent(ev){
        Crowded.triggerGameEvent(ev);
    }

    getPlayerId(){
        return Crowded._playerId;
    }

    getMe(){
        return Crowded.getMe();
    }

    getPlayerNumber(playerId = this.getPlayerId()){
        const playerIds = Object.values(this.getUsers()).map(u => u._data.id).sort();
        for(let i=0; i<playerIds.length; i++){
            if(playerIds[i] === playerId){
                return i;
            }
        }
        return -1;
    }

}

export default DataService;