import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { Octree } from './Octree.js';
import { Capsule } from 'three/examples/jsm/math/Capsule.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { getDataService } from './DataService.js';

import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

let _instance = undefined;
const STEPS_PER_FRAME = 5;
const GRAVITY = 30;

export function getRenderService() {
    if (!_instance) {
        _instance = new ThreeRenderService();
    }
    return _instance;
}

class ThreeRenderService {

    constructor(enableVr) {
        _instance = this;

        this.callbacks = {
            "hover": [],
            "mousedown": [],
            "mousemove": [],
            "mouseup": [],
            "sceneupdate": [],
            "scenetick": [],
        };


        this.updateHover = this.updateHover.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.animate = this.animate.bind(this);
        this.initialize = this.initialize.bind(this);
        this.controls = this.controls.bind(this);
        this.getForwardVector = this.getForwardVector.bind(this);
        this.getSideVector = this.getSideVector.bind(this);

        //this.enableVr = window.location.href.indexOf("room")>=0;
        this.enableVr = false;

        this.initialize();
    }

    addCallback(type, func) {
        if (this.callbacks[type]) {
            this.callbacks[type].push(func);
        }
    }

    triggerCallback(type, data) {
        if (this.callbacks[type]) {
            for (let cb of this.callbacks[type]) {
                cb(data, this);
            }
        }
    }

    addOctree(id, octree) {
        this.octrees[id] = octree;
    }

    getOctree(id) {
        return this.octrees[id];
    }

    removeOctree(id) {
        delete this.octrees[id];
    }

    initialize(elementId) {
        if(this.initialized) {
            return;
        }
        this.initialized = true;
        //document.documentElement.style.overflow="hidden";

        const self = this;


        document.addEventListener('keydown', (event) => {
            self.keyStates[event.code] = true;
        });

        document.addEventListener('keyup', (event) => {
            self.keyStates[event.code] = false;
        });

        document.body.addEventListener('mousemove', (event) => {

            let mouseX = event.clientX;
            let mouseY = event.clientY;

            window.mouseVector = new THREE.Vector2(
                2 * (mouseX / window.innerWidth) - 1,
                1 - 2 * (mouseY / window.innerHeight));

            if (document.pointerLockElement === document.body) {

                self.camera.rotation.y -= event.movementX / 500;
                self.camera.rotation.x -= event.movementY / 500;
                self.camera.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, self.camera.rotation.x));
            }
            this.triggerCallback('mousemove', undefined);
        });

        this.freeCamera = false;
        this.enablePointerLock = true;
        this.enableKeyboardControls = true;

        this.worldOctree = new Octree();
        this.octrees = {};
        this.playerOnFloor = false;

        this.keyStates = {};

        this.playerCollider = new Capsule(new THREE.Vector3(0, 0.35, 0), new THREE.Vector3(0, 1.5, 0), 0.35);

        this.playerVelocity = new THREE.Vector3();
        this.playerDirection = new THREE.Vector3();

        this.clock = new THREE.Clock();
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x000000);
        this.cam = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        //this.cam.rotation.order = 'YXZ';

        if(this.enableVr){
            this.camera = new THREE.Group()
            this.camera.rotation.order = 'YXZ';
            this.camera.add(this.cam);
            this.camera.position.set(0, 0.5, 0);
        }else{
            this.camera = this.cam;
            this.camera.rotation.order = 'YXZ';
            this.camera.position.set(0, 0.5, 0);
        }
        this.scene.add(this.camera);

        this.listener = new THREE.AudioListener();
        this.listener.setMasterVolume(0.8);
        this.camera.add( this.listener );

        const ambientlight = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(ambientlight);
        const fillLight1 = new THREE.DirectionalLight(0xff9999, 0.5);
        fillLight1.position.set(- 1, 1, 2);

        this.scene.add(fillLight1);
        const fillLight2 = new THREE.DirectionalLight(0x8888ff, 0.2);
        fillLight2.position.set(0, - 1, 0);
        this.scene.add(fillLight2);

        const directionalLight = new THREE.DirectionalLight(0xffffaa, 1.2);
        directionalLight.position.set(- 5, 25, - 1);
        this.scene.add(directionalLight);
        /*const ambientlight = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(ambientlight);
        const fillLight1 = new THREE.DirectionalLight(0xffffff, 0.25);
        fillLight1.position.set(- 1, 1, 2);
        this.scene.add(fillLight1);*/


        this.renderer = new THREE.WebGLRenderer();
        if(this.enableVr && false){
            this.renderer.xr.enabled = true;
            this.renderer.xr.setReferenceSpaceType('local-floor');
            document.body.appendChild( VRButton.createButton( self.renderer ) );
        }
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        this.renderer.domElement.addEventListener('mousedown', (event) => {
            if (this.enablePointerLock) {
                document.body.requestPointerLock();
            }
            this.triggerCallback('mousedown', event);
        });

        document.body.addEventListener('mousedown', (event) => {
            if (document.pointerLockElement === document.body) {
                this.triggerCallback('mousedown', event);
            }
        });

        document.body.addEventListener('mouseup', (event) => {
            this.triggerCallback('mouseup', event);
        });

        // postprocessing
        this.composer = new EffectComposer(this.renderer);
        let camera = this.camera;
        if(this.enableVr && false){
            camera = this.cam;
        }

        const renderPass = new RenderPass(this.scene, camera);
        this.composer.addPass(renderPass);

        this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene, camera);
        this.outlinePass.edgeStrength = 1.5;
        this.composer.addPass(this.outlinePass);

       
        this.stats = new Stats();
        this.stats.domElement.style.position = 'absolute';
        this.stats.domElement.style.top = '0px';

        //container.appendChild(this.stats.domElement);


        
    }

    addToDom(elementId){
        this.container = document.getElementById(elementId);
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.container.appendChild(this.renderer.domElement);
        window.addEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
        if(!this.container){
            return;
        }
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.composer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        //ssaoPass.setSize( window.innerWidth, window.innerHeight );

    }

    updatePlayer(deltaTime) {
        
        if(!this.enableKeyboardControls){
            return;
        }
        
        let damping = (Math.exp(- 4 * deltaTime) - 1)*1.5;

        if (!this.playerOnFloor) {

            this.playerVelocity.y -= GRAVITY * deltaTime;

            // small air resistance
            damping *= 0.1;

        }

        this.playerVelocity.addScaledVector(this.playerVelocity, damping);

        const deltaPosition = this.playerVelocity.clone().multiplyScalar(deltaTime);
        this.playerCollider.translate(deltaPosition);

        if (!this.freeCamera) {
            
            this.camera.position.copy(this.playerCollider.end);
            const session = this.renderer.xr.getSession();
            if (session && this.enableVr) {
                this.camera.position.y -= 1.35;
                //this.cam.position.x = 0;
                //this.cam.position.z = 0;
                
            }
        }

        this.playerCollisions();
    }

    playerCollisions() {
        const result = this.worldOctree.capsuleIntersect(this.playerCollider);
        this.playerOnFloor = false;
        if (result) {
            this.playerOnFloor = result.normal.y > 0;
            
            if (!this.playerOnFloor) {
                this.playerVelocity.addScaledVector(result.normal, - result.normal.dot(this.playerVelocity));
            }
            this.playerCollider.translate(result.normal.multiplyScalar(result.depth));
        }

        for(let o in this.octrees){
            const result = this.octrees[o].capsuleIntersect(this.playerCollider);
            if (result) {
                this.playerCollider.translate(result.normal.multiplyScalar(result.depth));
            }
        }

    }

    getForwardVector() {

        this.cam.getWorldDirection(this.playerDirection);
        this.playerDirection.y = 0;
        this.playerDirection.normalize();

        return this.playerDirection;

    }

    getSideVector() {

        this.cam.getWorldDirection(this.playerDirection);
        this.playerDirection.y = 0;
        this.playerDirection.normalize();
        this.playerDirection.cross(this.camera.up);

        return this.playerDirection;

    }

    controls(deltaTime) {
        if(!this.enableKeyboardControls){
            return;
        }

        // gives a bit of air control
        const speedDelta = deltaTime * (this.playerOnFloor ? 25 : 8)*0.6;

        getDataService().getMe().updateLocalState({movement: "idle"});
        if (this.keyStates['KeyW']) {
            this.playerVelocity.add(this.getForwardVector().multiplyScalar(speedDelta));
            getDataService().getMe().updateLocalState({movement: "forward"});
        }

        if (this.keyStates['KeyS']) {
            this.playerVelocity.add(this.getForwardVector().multiplyScalar(- speedDelta));
            getDataService().getMe().updateLocalState({movement: "backward"});
        }

        if (this.keyStates['KeyA']) {
            this.playerVelocity.add(this.getSideVector().multiplyScalar(- speedDelta));
            getDataService().getMe().updateLocalState({movement: "left"});
        }

        if (this.keyStates['KeyD']) {
            this.playerVelocity.add(this.getSideVector().multiplyScalar(speedDelta));
            getDataService().getMe().updateLocalState({movement: "right"});
        }

        // vr
        const session = this.renderer.xr.getSession();
        let i = 0;

        if (session && this.enableVr) {
            //this.camera.position.y = 0;
            this.camera.rotation.set(0, 0, 0);
            let handedness;
            for (const source of session.inputSources) {
                if (source && source.handedness) {
                    handedness = source.handedness; //left or right controllers
                }
                if (!source.gamepad) continue;
                const controller = this.renderer.xr.getController(i++);
                //const old = prevGamePads.get(source);
                const data = {
                    handedness: handedness,
                    buttons: source.gamepad.buttons.map((b) => b.value),
                    axes: source.gamepad.axes.slice(0)
                };
                var sum = data.axes.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue
                  },0);
                if(sum!=0){
                    
                     console.log(data.axes);
                }
                if(data.handedness == "left"){
                    
                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(- speedDelta*data.axes[3]));
                    this.playerVelocity.add(this.getSideVector().multiplyScalar(speedDelta*data.axes[2]));
                }
            }
        }

        getDataService().getMe().updateLocalState({air: this.playerOnFloor});
        if (this.playerOnFloor) {
            if (this.keyStates['Space']) {
                this.playerVelocity.y = 8;
            }
        }
    }

    teleportPlayerIfOob() {

        if (this.camera.position.y <= - 25) {

            this.playerCollider.start.set(0, 0.35, 0);
            this.playerCollider.end.set(0, 1.7, 0);
            this.playerCollider.radius = 0.35;
            this.camera.position.copy(this.playerCollider.end);
            this.camera.rotation.set(0, 0, 0);

        }

    }

    updateHover() {
        var raycaster = new THREE.Raycaster();
        if (this.freeCamera) {
            if(window.mouseVector){
                raycaster.setFromCamera(window.mouseVector, this.cam);
            }
        } else {
            raycaster.setFromCamera(new THREE.Vector2(), this.cam);
        }

        var intersects = raycaster.intersectObjects(this.scene.children);

        this.triggerCallback("hover", intersects);
    }


    animate() {
        let size = new THREE.Vector2();
        this.renderer.getSize ( size );
        if(size.x != this.container.offsetWidth ||size.y != this.container.offsetHeight){
            this.onWindowResize();
        }

        const deltaTime = Math.min(0.05, this.clock.getDelta()) / STEPS_PER_FRAME;

        // we look for collisions in substeps to mitigate the risk of
        // an object traversing another too quickly for detection.

        for (let i = 0; i < STEPS_PER_FRAME; i++) {

            this.controls(deltaTime);

            this.updatePlayer(deltaTime);

            this.teleportPlayerIfOob();

        }

        //renderer.render( scene, camera );
        this.composer.render();
        this.stats.update();

        this.updateHover();

        this.triggerCallback("scenetick", deltaTime*STEPS_PER_FRAME);
        const self = this;
        if(this.enableVr){
            this.renderer.setAnimationLoop( function () {
            
                self.animate();
                self.renderer.render( self.scene, self.cam );
            
            } );
        }else{
            window.requestAnimationFrame(this.animate);
        }
        //
        

    }

    reinitialize() {
        this.renderer.forceContextLoss();
        const container = document.getElementById('three-container').innerHTML = "";
        this.renderer.context = null;
        this.renderer.domElement = null;
        this.renderer = null;
        this.scene = undefined;
        this.stop = true;
        this.initialize();
    }

}

export default ThreeRenderService;