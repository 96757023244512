import './CombinationLock.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';


class CombinationLock extends Component {
  constructor(props) {
    super(props);

    this.state = { numbers: [0, 0, 0] };
    const self = this;

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.result = this.result.bind(this);
  }

  close(){
    if(this.props.onClose){
      this.props.onClose();
    }
  }

  result(){
    let val1 = ("0"+this.state.numbers[0]).slice(-1);
    let val2 = ("0"+this.state.numbers[1]).slice(-1);
    let val3 = ("0"+this.state.numbers[2]).slice(-1);

    const name = this.props["selectedObject"];

    if (name.indexOf("dungeon_1") >= 0) {
      getDataService().triggerGameEvent({"event":"combination_lock", "lock": 1, val1: val1, val2:val2, val3:val3});
    } else if (name.indexOf("dungeon_2") >= 0) { 
      getDataService().triggerGameEvent({"event":"combination_lock", "lock": 2, val1: val1, val2:val2, val3:val3});
    } else if (name.indexOf("chest") >= 0) { 
      getDataService().triggerGameEvent({"event":"combination_lock", "lock": 3, val1: val1, val2:val2, val3:val3});
    } else if (name.indexOf("door_1") >= 0) { 
      getDataService().triggerGameEvent({"event":"combination_lock", "lock": 4, val1: val1, val2:val2, val3:val3});
    } else if (name.indexOf("door_2") >= 0) { 
      getDataService().triggerGameEvent({"event":"combination_lock", "lock": 5, val1: val1, val2:val2, val3:val3});
    }else{
      if(this.props.onResult){
        if(!this.props.onResult(this.state.numbers)){
          getDataService().triggerGameEvent({"event":"combination_lock", "lock": -1, val1: val1, val2:val2, val3:val3});
        }
        return;
      }
    }
    if(this.props.onResult){
      this.props.onResult(this.state.numbers);
    }
    
  }

  handleChange(index, event) {
    const numbers = this.state.numbers;
    if ((event.target.value + "").length < 1) {
      numbers[index] = "";
      this.setState({ numbers: numbers });
      return;
    }

    const newValue = Math.min(9, Math.max(0, parseInt(event.target.value, 10)));
    numbers[index] = newValue;
    this.setState({ numbers: numbers });
  }

  render() {
    let selection = undefined;
    let title = getTranslationService().translate("combinationlock_title");
    if (this.props.selectedObject) {
      selection = this.props.selectedObject;
      if(getTranslationService().translate(selection)){
        title = getTranslationService().translate(selection);
      }
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.close}></div>
        <div className="card dialog" onClick={(e)=>{e.stopPropagation();}}>
          <div className="card-content">
            <h1 className="title">{title}</h1>
            <div className="field">
              <div className="control">
                <input className="input is-medium is-text-centered" type="number" placeholder="0" onChange={(e) => { this.handleChange(0, e); }} value={this.state.numbers[0]}></input>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input className="input is-medium is-text-centered" type="number" placeholder="0" onChange={(e) => { this.handleChange(1, e); }} value={this.state.numbers[1]}></input>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input className="input is-medium is-text-centered" type="number" placeholder="0" onChange={(e) => { this.handleChange(2, e); }} value={this.state.numbers[2]}></input>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button className="button is-fullwidth is-primary is-medium" onClick={this.result}>&#10132;</button>
              </div>
            </div>


          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.close}></button>
      </div>
    );
  }

}

export default CombinationLock;
