
import * as THREE from 'three';
import { getRenderService } from './ThreeRenderService';

let _instance = undefined;

export function getSoundService() {
    if (!_instance) {
        _instance = new DataService();
    }
    return _instance;
}

class DataService {

    constructor() {
        _instance = this;
        this.buffers = {};
        this.sounds = {};
        this.volume = 0.7;
    }

    getVolume(){
        return this.volume;
    }

    setVolume(newVolume){
        this.volume = newVolume;
    }

    loadSound(name, path, options){
        if(!options){
            options = {};
        }
        const self = this;

        if(!getRenderService().listener){
            setTimeout(() => self.loadSound(name, path, options), 1000);
        }

        const audioLoader = new THREE.AudioLoader();
        audioLoader.load( path, function( buffer ) {
            self.buffers[path] = buffer;
            if(!options.mesh){
                const sound = new THREE.Audio( getRenderService().listener );
                sound.setBuffer( buffer );
                sound.setLoop(options.loop);
                if(!options.autoplay){
                    sound.stop();
                }else{
                    sound.play();
                }
                self.sounds[name] = sound;
            }else{
                const sound = new THREE.PositionalAudio( getRenderService().listener );
                sound.setBuffer( buffer );
                sound.setRefDistance( 10 );
                sound.setLoop(options.loop);
                if(options.autoplay){
                    sound.play();
                }
                self.sounds[name] = sound;
                options.mesh.add(sound);
            }
        });
    }

    playSound(name, options){
        if(!options){
            options = {};
        }

        if(this.sounds[name]){
            this.sounds[name].setLoop(options.loop);
            if(this.sounds[name].isPlaying && !options.noStop){
                this.sounds[name].stop();
            }
            this.sounds[name].play();
            //debugger;
        }
    }

    stopSound(name, options){
        if(!options){
            options = {};
        }

        if(this.sounds[name]){
            if(this.sounds[name].isPlaying){
                this.sounds[name].stop();
            }
        }
    }
}

export default DataService;