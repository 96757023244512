
import './BookDialog.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';


class BookDialog extends Component {
  constructor(props) {
    super(props);

    this.state = { page: 1, pageText: 1 };
    const self = this;

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.result = this.result.bind(this);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  result() {

    if (this.props.onResult) {
      this.props.onResult(this.state.numbers);
    }
  }

  handleChange(newPage) {
    const self = this;
    let page = this.state.page;
    if ((newPage + "").length < 1) {
      page = "";
      this.setState({ pageText: page });
      return;
    }

    const newValue = Math.min(this.getNumberOfPages(), Math.max(1, parseInt(newPage, 10)));
    if (newValue > page) {
      this.setState({ direction: 0 });
      window.setTimeout(() => { self.setState({ direction: +1 }) }, 1);
    }
    if (newValue < page) {
      this.setState({ direction: 0 });
      window.setTimeout(() => { self.setState({ direction: -1 }) }, 1);
    }
    page = newValue;
    this.setState({ page: page, pageText: page });
  }

  getNumberOfPages() {
    switch (this.props.selectedObject) {
      case "SpaceBook1": return 4;
      case "SpaceBook2": return 20;
      case "SpaceBook3": return 103;
      case "SpaceBook4": return 4;
      default: return 100;
    }
  }

  getErrorTable(page_number) {
    let xOff = page_number % 10;
    let yOff = Math.floor(page_number / 10);
    let rows = [];
    for (let y = -1; y < 10; y++) {
      let cells = [<td><b>{y < 0 ? "" : yOff * 10 + y}</b></td>];
      for (let x = 0; x < 10; x++) {
        let curNum = (Math.pow(xOff * 7 + x, 2) + Math.pow(yOff * 7 + y, 2)) % 10;
        cells.push(<td>{y == -1 ? <b>{xOff * 10 + x}</b> : curNum}</td>);
      }
      rows.push(<tr>{cells}</tr>);
    }
    return <table style={{ width: "100%", height: "100%", textAlign: "center" }}>{rows}</table>;
  }

  getPowerMatrix(page_number) {
    return <img style={{ height: "100%" }} src='/images/space_power_matrix.png' />
  }

  getPageContent(page_number) {
    switch (this.props.selectedObject) {
      case "SpaceBook1":
        if (getTranslationService().getLang() == "de") {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Notfall System Handbuch</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 1.0.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Gültigkeit:</i><br /><b>Manueller Türzugriff im Falle einer Systemweiten Fehlfunktion oder Notfallevakuierung.</b></div>];
            case 4:
              return [<div style={{ fontSize: "0.4em", }}>Geben Sie die 3 Stellen in das Türterminal ein:<br /><br /><table style={{ width: "100%", border: "1px solid black" }}><tr><td style={{ width: "30%" }}>Zeichen</td><td>Weitere Informationen</td></tr><tr><td>#1</td><td>System Terminal Handbuch</td></tr><tr><td>#2</td><td>Fehlercode Handbuch</td></tr><tr><td>#3</td><td>Energiematrix Manual</td></tr></table></div>];
          }
        } else {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Emergency Overwrite Protocol</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 1.0.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Case of Usage:</i><br /><b>Manual Door Overwrite in case of System Malfunction or Emergency Evacuation.</b></div>];
            case 4:
              return [<div style={{ fontSize: "0.4em", }}>Enter the 3 digits into the terminal:<br /><br /><table style={{ width: "100%", border: "1px solid black" }}><tr><td style={{ width: "30%" }}>Digit</td><td>Refer to</td></tr><tr><td>#1</td><td>System Terminal Manual</td></tr><tr><td>#2</td><td>Error Code Manual</td></tr><tr><td>#3</td><td>Power Matrix Manual</td></tr></table></div>];
          }
        }
        break;
      case "SpaceBook2":
        if (getTranslationService().getLang() == "de") {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>System Terminal Handbuch</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 3.1.4_b_final2</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Gültigkeit:</i><br /><b>Bedienung des System Terminals</b></div>];
            case 4:
              return [<div style={{ fontSize: "0.4em" }}><b>Notschlüssel</b><br /></div>];
            default:
              return [<div style={{ fontSize: "0.4em" }}><b>Konfiguration</b><br />Spannung "Main": {(page_number - 4) % 2 == 0 ? "<" : ">="} 30V<br />Spannung "Nav": {(Math.floor((page_number - 4) / 2) % 2) == 0 ? "<" : ">="} 20V<br />Spannung "Bio": {(Math.floor((page_number - 4) / 4) % 2) == 0 ? "<" : ">="} 10V<br />Spannung "Lab": {(Math.floor((page_number - 4) / 8) % 2) == 0 ? "<" : ">="} 0V<br /><br /><u>Notschlüssel: {(page_number - 4) % 10}</u></div>];
          }
        } else {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>System Terminal Manual</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 3.1.4_b_final2</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Case of Usage:</i><br /><b>Operation of the System Terminal</b></div>];
            case 4:
              return [<div style={{ fontSize: "0.4em" }}><b>Master keys</b><br /></div>];
            default:
              return [<div style={{ fontSize: "0.4em" }}><b>Setting</b><br />Main Voltage: {(page_number - 4) % 2 == 0 ? "<" : ">="} 30V<br />Nav Voltage: {(Math.floor((page_number - 4) / 2) % 2) == 0 ? "<" : ">="} 20V<br />Bio Voltage: {(Math.floor((page_number - 4) / 4) % 2) == 0 ? "<" : ">="} 10V<br />Lab Voltage: {(Math.floor((page_number - 4) / 8) % 2) == 0 ? "<" : ">="} 0V<br /><br /><u>Master code: {(page_number - 4) % 10}</u></div>];
          }
        }

        break;
      case "SpaceBook3":
        if (getTranslationService().getLang() == "de") {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Fehlercode Manual</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 0.1.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Gültigkeit:</i><br /><b>Fehlercodes können jederzeit auftreten.</b></div>];
            default:
              return [<div className='errorTable' style={{ fontSize: "0.4em", width: "100%", height: "100%" }}>{this.getErrorTable(page_number - 4)}</div>];
          }
        } else {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Error Code Manual</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 0.1.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Case of Usage:</i><br /><b>Lookup of Error Codes.</b></div>];
            default:
              return [<div className='errorTable' style={{ fontSize: "0.4em", width: "100%", height: "100%" }}>{this.getErrorTable(page_number - 4)}</div>];
          }
        }
        break;
      case "SpaceBook4":
        if (getTranslationService().getLang() == "de") {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Energiematrix Manual</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 1.2.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Gültigkeit:</i><br /><b>Notschlüssel Ermittlung der Energiematrix.</b></div>];
            default:
              return [<div className='errorTable' style={{ fontSize: "0.4em", width: "100%", height: "100%" }}>{this.getPowerMatrix(page_number - 4)}</div>];
          }
        } else {
          switch (page_number) {
            case 1:
              return <b style={{ fontSize: "0.6em" }}>Power Matrix Manual</b>;
            case 2:
              return <p style={{ fontSize: "0.4em" }}>Version 1.2.3</p>;
            case 3:
              return [<div style={{ fontSize: "0.4em" }}><i>Case of Usage:</i><br /><b>Lookup of Power Matrix Master Key.</b></div>];
            default:
              return [<div className='errorTable' style={{ fontSize: "0.4em", width: "100%", height: "100%" }}>{this.getPowerMatrix(page_number - 4)}</div>];
          }
        }
        break;
    }

    let pageContent = [];
    const colors = ["#861f13", "#cebe37", "#5333d3"];
    let randNumbA = 1;
    let randNumbB = 1;
    let randNumbC = 1;
    for (let i = 0; i < this.props.selectedObject.length; i++) {
      let numb = this.props.selectedObject.charCodeAt(i);
      randNumbA *= numb + page_number;
      randNumbB += numb;
      randNumbC += numb + randNumbB;
    }
    randNumbA += page_number;
    randNumbB += page_number;
    randNumbC += page_number;

    randNumbA = randNumbA % 10;
    randNumbB = randNumbB % 10;
    randNumbC = randNumbC % 10;
    pageContent.push(<p style={{ color: colors[randNumbA % 3] }}>{randNumbA}</p>);
    pageContent.push(<p style={{ color: colors[randNumbA % 3] }}>{randNumbB}</p>);
    pageContent.push(<p style={{ color: colors[randNumbA % 3] }}>{randNumbC}</p>);
    pageContent.push(<div className='page-number'>{page_number}</div>);
    return pageContent;
  }

  render() {
    let selection = undefined;
    if (this.state.selectedObject) {
      selection = this.state.selectedObject.object.name;
    }

    let animation = "";
    if (this.state.direction < 0) {
      animation = "scroll-backward";
    }
    if (this.state.direction > 0) {
      animation = "scroll-forward";
    }

    let rightPage = this.state.page;
    let animatePage = this.state.page;
    if (this.state.direction > 0) {

      rightPage = this.state.page;
      animatePage = this.state.page - 1;
    }
    if (this.state.direction < 0) {

      rightPage = this.state.page + 1;
      animatePage = this.state.page;
    }

    let title = "Buch";
    if (getTranslationService().translate(this.props.selectedObject)) {
      title = getTranslationService().translate(this.props.selectedObject);
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.close}></div>
        <div className="card dialog" onClick={(e) => { e.stopPropagation(); }}>
          <div className="card-content">
            <h1 className="title">{title}</h1>
            <div className='page-container'>
              <div className='page page-left'></div>
              <div className='page page-right'>
                {this.getPageContent(rightPage)}
              </div>
              <div className={'page page-current ' + animation}>
                <div className='page-front'>{this.getPageContent(animatePage)}</div>
                <div className='page-back'></div>
              </div>
            </div>
            <div className="field">
              <div className="control page-control">
                <button className='button' onClick={() => { this.handleChange(this.state.page - 1) }}>&lt;</button>
                <input className="input is-text-centered" type="number" placeholder="0" onChange={(e) => { this.handleChange(e.target.value); }} value={this.state.pageText}></input>
                <button className='button' onClick={() => { this.handleChange(this.state.page + 1) }}>&gt;</button>
              </div>
            </div>

          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.close}></button>
      </div>
    );
  }

}

export default BookDialog;
